.mainContentArea {
  position: fixed;
  top: 100px;
  left: 338px;
  width: calc(100% - 335px);
  height: 86vh;
  background: #efefef;
  transition: 0.5s;
  overflow: auto;
  /* padding: 32px; */
  /* font-family: "Galano Grotesque", sans-serif; */
  /* background-color: red; */
}

.mainContentArea.active {
  width: calc(100% - 70px);
  left: 70px;
}

.mainContentArea-ar{
  position: fixed;
  top: 100px;
  right: 338px;
  width: calc(100% - 335px);
  height: 86vh;
  background: #efefef;
  transition: 0.5s;
  overflow: auto;
}

.mainContentArea-ar.active{
  width: calc(100% - 70px);
  right: 70px;
}

/* @media screen and (max-width: 1024px) {
  .mainContentArea {
    padding: 20px;
  }
} */
