/* custom style for all element according to figma design*/
body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: var(--neutral-white-03);
}

/* layout main div container */
.mainContainer {
  position: relative;
}

/* ==========================================================================
  01. Root variables for application colours
  =========================================================================== */

:root {
  --bg-color-black: #000000;
  --neutral-white-00: #ffffff;
  --neutral-white-01: #fcfcfc;
  --neutral-white-02: #f4f4f4;
  --neutral-white-03: #efefef;
  --neutral-grey-04: #6f767e;
  --neutral-grey-05: #33383f;
  --neutral-grey-06: #272b30;
  --neutral-grey-07: #1a1d1f;
  --neutral-grey-08: #111315;
  --shades-grey-dark-00: #9a9fa5;
  --shades-grey-dark-01: #6f767e;
  --shades-grey-dark-02: #6f767e;
  --shades-grey-dark-03: #111315;
  --secondary-color-01: #ffbc99;
  --secondary-color-02: #cabdff;
  --secondary-color-03: #b1e5fc;
  --secondary-color-04: #b5e4ca;
  --secondary-color-05: #ffd88d;
  --brand-color-01: #e69dff;
  --brand-color-02: #83bf6e;
  --brand-color-03: #ff6a55;
  --brand-color-04: #8e59ff;
  --brand-color-05: #33ff91;
  --button-bg-color: rgba(42, 133, 255, 0.5);
  --button-color: rgba(252, 252, 252, 0.75);
  --button-bg-color-01: #2a85ff;
  --text-color-01: #ff4183;
  --text-color-02: #ff6e48;
  --text-color-03: #666cff;
}

.font-bold {
  font-weight: 600;
}

.font-bolder {
  font-weight: 500;
}

.font-boldest {
  font-weight: 700;
}

.font-5 {
  font-size: 5px;
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-19 {
  font-size: 19px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-32 {
  font-size: 32px;
}

.font-35 {
  font-size: 35px;
}

.font-40 {
  font-size: 40px;
}

.font-48 {
  font-size: 48px;
}

.fit-content {
  width: fit-content;
}

.width-98 {
  width: 98%;
}

.width-80 {
  width: 80%;
}

.width-75 {
  width: 75%;
}

.width-70 {
  width: 70%;
}

.width-65 {
  width: 65%;
}

.width-45 {
  width: 45%;
}

.width-35 {
  width: 35%;
}

.width-30 {
  width: 30%;
}

.width-25 {
  width: 25%;
}

.width-20 {
  width: 20%;
}

.bg-01 {
  background-color: var(--bg-color-black);
}

.bg-02 {
  background-color: var(--secondary-color-02);
}

.bg-03 {
  background-color: var(--neutral-grey-07);
}

.bg-04 {
  background-color: var(--secondary-color-04);
}

.bg-05 {
  background-color: var(--secondary-color-03);
}

.bg-06 {
  background-color: var(--button-bg-color-01);
}

.button-bg-color {
  background-color: var(--button-bg-color-01);
  color: #fff;
}

.button-bg-color-secondary {
  background-color: var(--neutral-white-03);
}

.bg-body-color {
  background-color: var(--neutral-white-03);
}

.bg-light {
  background-color: var(--neutral-white-03) !important;
}

.text-light-blue {
  color: var(--button-bg-color-01);
}

.text-pink {
  color: var(--text-color-01);
}

.text-orange {
  color: var(--text-color-02);
}

.text-dark-blue {
  color: var(--text-color-03);
}

.text-error {
  color: red;
  font-size: 14px;
  font-weight: 500;
}

.text-error-client-supplier {
  color: red;
  font-size: 10px;
  font-weight: 500;
}

.neutral-white-00 {
  color: var(--neutral-white-00);
}

.neutral-white-01 {
  color: var(--neutral-white-01);
}

.neutral-white-02 {
  color: var(--neutral-white-02);
}

.neutral-white-03 {
  color: var(--neutral-white-03);
}

.neutral-grey-04 {
  color: var(--neutral-grey-04);
}

.neutral-grey-05 {
  color: var(--neutral-grey-05);
}

.neutral-grey-06 {
  color: var(--neutral-grey-06);
}

.neutral-grey-07 {
  color: var(--neutral-grey-07);
}

.neutral-grey-08 {
  color: var(--neutral-grey-08);
}

.shades-grey-dark-00 {
  color: var(--shades-grey-dark-00);
}

.shades-grey-dark-01 {
  color: var(--shades-grey-dark-01);
}

.shades-grey-dark-02 {
  color: var(--shades-grey-dark-02);
}

.shades-grey-dark-03 {
  color: var(--shades-grey-dark-03);
}

.secondary-color-01 {
  color: var(--secondary-color-01);
}

.secondary-color-02 {
  color: var(--secondary-color-02);
}

.secondary-color-03 {
  color: var(--secondary-color-03);
}

.secondary-color-04 {
  color: var(--secondary-color-04);
}

.secondary-color-05 {
  color: var(--secondary-color-05);
}

.brand-color-01 {
  color: var(--brand-color-01);
}

.brand-color-02 {
  color: var(--brand-color-02);
}

.brand-color-03 {
  color: var(--brand-color-03);
}

.brand-color-04 {
  color: var(--brand-color-04);
}

.brand-color-05 {
  color: var(--brand-color-05);
}

/* Rotate icon 180 degree */
.rotate-180 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.heading-text {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  height: 48px;
  /* identical to box height, or 100% */
  letter-spacing: -0.03em;
  /* Neutral/07 */
  color: var(--font-color-black);
}

.heading-caption-text {
  font-style: normal;
  line-height: 16px;
  height: 16px;
  /* identical to box height, or 123% */
  letter-spacing: -0.01em;
  /* Neutral/07 */
  color: var(--font-color-black);
}

.input-custom {
  height: 48px;
  left: 893px;
  top: 437px;
  /* Neutral/02 */
  background: var(--neutral-white-02);
  border-radius: 3px;
  border: none;
  resize: none;
  outline: none;
}

/* ==========================================================================
  01. Root classes for Typography
  =========================================================================== */
.tg-text-faded {
  height: 64px;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 64px;
  /* identical to box height, or 229% */
  letter-spacing: -0.03em;
  color: var(--neutral-grey-04);
}

.tg-text-lg {
  height: 103px;
  font-style: normal;
  font-weight: 600;
  font-size: 103px;
  line-height: 103px;
  /* identical to box height, or 100% */
  letter-spacing: -0.03em;
  /* Neutral/07 */
  color: var(--neutral-grey-07);
}

.tg-text-sm {
  height: 56px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* or 140% */
  letter-spacing: -0.02em;
  /* Neutral/07 */
  color: var(--neutral-grey-07);
}

.tg-text-sm-head {
  width: 686px;
  height: 48px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */

  letter-spacing: -0.02em;

  /* Neutral/07 */
  color: var(--neutral-grey-07);
}

.tg-text-sm-head-lc {
  width: 686px;
  height: 48px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */

  letter-spacing: -0.02em;
  text-transform: lowercase;
  /* Neutral/07 */
  color: var(--neutral-grey-07);
}

.ts-h1 {
  width: 240px;
  height: 64px;
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 64px;
  /* identical to box height, or 100% */

  letter-spacing: -0.03em;

  /* Neutral/07 */
  color: var(--neutral-grey-07);
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-h2 {
  width: 240px;
  height: 48px;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  /* identical to box height, or 100% */

  letter-spacing: -0.03em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-h3 {
  width: 240px;
  height: 48px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */

  letter-spacing: -0.02em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-h4 {
  width: 240px;
  height: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  letter-spacing: -0.03em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-title-sb {
  width: 163px;
  height: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  letter-spacing: -0.02em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 4;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-title-md {
  width: 149px;
  height: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  letter-spacing: -0.02em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 5;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-base1-b {
  width: 94px;
  height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */

  letter-spacing: -0.01em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 7;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-base1-sb {
  width: 128px;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */

  letter-spacing: -0.01em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 6;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-base2 {
  width: 45px;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.01em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 8;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-body1-md {
  width: 240px;
  height: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */

  letter-spacing: -0.015em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 9;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-body1-sb {
  width: 240px;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */

  letter-spacing: -0.01em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 10;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-body2-sb {
  width: 240px;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.01em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 11;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-caption1 {
  width: 58px;
  height: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  letter-spacing: -0.01em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 12;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-caption1-md {
  width: 120px;
  height: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  letter-spacing: -0.01em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 13;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-caption2 {
  width: 56px;
  height: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: -0.01em;

  /* Neutral/07 */
  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 14;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-caption2-md {
  width: 112px;
  height: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  /* or 100% */

  letter-spacing: -0.01em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 15;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-btn1 {
  width: 60px;
  height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */

  letter-spacing: -0.01em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 16;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-btn2 {
  width: 54px;
  height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */

  letter-spacing: -0.01em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 17;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-divider {
  width: 240px;
  height: 1px;
  background: var(--neutral-white-03);
  border-radius: 1px;

  /* Inside auto layout */

  flex: none;
  order: 18;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0px;
}

.ts-title1-mob {
  width: 124px;
  height: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */

  letter-spacing: -0.02em;

  /* Neutral/07 */

  color: var(--neutral-grey-07);

  /* Inside auto layout */

  flex: none;
  order: 19;
  flex-grow: 0;
  margin: 24px 0px;
}

.Inputwithicon input[type="text"] {
  padding-left: 45px;
  border: 1px solid var(--neutral-white-03);
  border-width: 0px;
}

.Inputwithicon #search {
  position: absolute;
  left: 0;
  top: -7px;
  padding: 16px 15px;
  color: var(--neutral-grey-04);
  border-radius: 8px 0px 0px 8px;
}

.Inputwithicon input[name="search"] {
  padding-left: 45px;
  border-radius: 8px 8px 8px 8px;
}

.Inputwithicon input[type="password"] {
  padding-left: 48px;
  border: none;
}

.Inputwithicon {
  position: relative;
}

.Inputwithicon #txt-icon {
  position: absolute;
  left: 0;
  top: -7px;
  padding: 16px 15px;
  color: var(--neutral-grey-04);
  /* background-color: var(--neutral-white-02); */
  border-radius: 8px 0px 0px 8px;
}

.Inputwithicon-ar input[type="text"] {
  padding-right: 45px;
  border: 1px solid var(--neutral-white-03);
  border-width: 0px;
}

.Inputwithicon-ar #search {
  position: absolute;
  right: 0;
  top: -7px;
  padding: 16px 15px;
  color: var(--neutral-grey-04);
  border-radius: 8px 0px 0px 8px;
}

.Inputwithicon-ar input[name="search"] {
  padding-right: 45px;
  border-radius: 8px 8px 8px 8px;
}

.Inputwithicon-ar input[type="password"] {
  padding-right: 48px;
  border: none;
}

.Inputwithicon-ar {
  position: relative;
}

.Inputwithicon-ar #txt-icon {
  position: absolute;
  right: 0;
  top: -7px;
  padding: 16px 15px;
  color: var(--neutral-grey-04);
  /* background-color: var(--neutral-white-02); */
  border-radius: 8px 0px 0px 8px;
}

.icononimage {
  position: relative;
}

.icononimage #imgicon {
  position: absolute;
  left: 220px;
  top: 310px;
  z-index: 1;
  width: fit-content;
}

.link-text {
  color: inherit;
  text-decoration: inherit;
}

.link-text-color {
  color: var(--button-bg-color-01);
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: inherit;
}

a:hover {
  color: inherit;
}

.hover-row {
  cursor: pointer;
}

.hover-row:hover {
  background-color: var(--neutral-white-03) !important;
}

.hover-row-border {
  cursor: pointer;
  border-color: var(--button-bg-color-01) !important;
}

.hover-row-border:hover {
  border-color: var(--button-bg-color-01) !important;
  background-color: var(--neutral-white-03) !important;
  border-width: 1px;
  border-style: solid;
}

.rounded-card {
  border-radius: 1rem !important;
}

.rounded-card-7 {
  border-radius: 0.7rem !important;
}

.rounded-circle {
  border-radius: 10rem !important;
}

.cursor {
  cursor: pointer;
}

.default-cursor {
  cursor: default !important;
}

.outline-none {
  outline: none;
}

.border-none {
  border: none;
}

.border-line {
  border: 1px solid var(--neutral-white-03);
}

.border-solid {
  border: 2px solid var(--neutral-white-03);
}

.box-shadow-none {
  box-shadow: none;
}

.body-table {
  flex: 1 1 auto;
  padding: 0.3rem 0.3rem;
}

/* fileUpload */
.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > input[type="file"] {
  display: none;
}

/* group Input field */
.border-radius-right {
  border-radius: 0.8rem 0rem 0rem 0.8rem !important;
}

.border-radius-left {
  border-radius: 0rem 0.8rem 0.8rem 0rem !important;
}

.border-radius-right-ar {
  border-radius: 0rem 0.8rem 0.8rem 0rem !important;
}

.border-radius-left-ar {
  border-radius: 0.8rem 0rem 0rem 0.8rem !important;
}

/* Rich text box */
.border {
  border: 2px solid #dee2e6 !important;
}

.rdw-option-wrapper {
  border: 2px solid #f1f1f1 !important;
  padding: 5px;
  min-width: 25px;
  border-radius: 2px !important;
  height: 30px !important;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.rdw-dropdown-wrapper {
  height: 30px;
  background: white;
  cursor: pointer;
  border: 2px solid #f1f1f1 !important;
  border-radius: 2px;
  margin: 0 3px;
  text-transform: capitalize;
  background: white;
}

.rdw-option-wrapper:hover {
  /* box-shadow:  #bfbdbd !important; */
  background-color: white !important;
}

.rdw-editor-toolbar {
  padding: 4px !important;
  border-radius: 0.8rem 0.8rem 0rem 0rem !important;
  border: 0px solid #f1f1f1 !important;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
}

.rdw-option-active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}

.public-DraftStyleDefault-block {
  margin: 0.1em 0 !important;
}

.rdw-editor-main {
  padding: 15px !important;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}

/* date picker */
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid var(--neutral-white-03);
  /* font-family: Arial, Helvetica, sans-serif; */
  line-height: 1.125em;
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  outline: 0px;
  -moz-appearance: textfield;
}

.date-picker-lable {
  position: "relative";
  left: 0;
}

.date-picker-lable-right {
  position: "relative";
  right: 0;
}

.date-picker-main {
  position: "relative";
  display: "flex";
  width: "100%";
}

/* input field */
.form-control:focus {
  color: #212529;
  background-color: #fff;
  outline: 0;
  box-shadow: none;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.37rem 0.3rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

 input[type=number] {
  -moz-appearance: textfield;
}
 */

/* input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
} */

/* Switch button */
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

.btn-outline-secondary:hover {
  color: #000000 !important;
  background-color: #fff !important;
  border-color: var(--neutral-white-03) !important;
}

.btn-outline-secondary:focus {
  box-shadow: inherit !important;
}

.btn-outline-secondary {
  color: #000000 !important;
  border-color: var(--neutral-white-03) !important;
}

.input-group .btn {
  position: relative;
  z-index: 0 !important;
}

/* Buttons */

.primary-btn {
  background-color: var(--button-bg-color-01);
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0.3rem;
  cursor: pointer;
}

.secondary-btn {
  background-color: var(--neutral-white-03);
  border: none;
  color: black;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0.3rem;
  cursor: pointer;
}

.rounded-btn {
  background-color: white;
  border: 2px solid var(--neutral-white-03);
  color: black;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0.8rem;
  cursor: pointer;
}

/* new added */
.rounded-btn:hover {
  background-color: var(--button-bg-color-01);
  color: var(--neutral-white-03);
}

.square-btn {
  background-color: var(--neutral-white-03);
  border: 1px solid var(--button-bg-color-01);
  color: black;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0.3rem;
  cursor: pointer;
}

.border-btn {
  background-color: #fff;
  border: 2px solid var(--shades-grey-dark-00);
  color: black;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0.8rem;
  cursor: pointer;
}

/* dropdown */
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: inherit;
}

.dropdown-menu.show {
  display: block;
  width: 100%;
}

/* date picker */
.react-date-picker {
  display: inline-flex;
  position: relative;
  width: 100%;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* border-radius: 0.8rem; */
  border: 0;
}

.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: 0 !important;
  padding: 10px;
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
  text-align: center;
}

.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}

.react-date-picker__button svg {
  display: inherit;
}

.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: relative;
  top: 100%;
  left: 0;
  z-index: 1;
}

.react-date-picker__calendar--closed {
  display: none;
}

.react-date-picker__calendar .react-calendar {
  /* border-width: thin; */
  position: absolute;
  top: 0px;
  right: 200px;
}

/* circular progress bar */
.count-circle {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  display: inline-block;
  border: 1px solid var(--button-bg-color);
  border-radius: 50%;
  font-family: Arial;
  color: #888;
}

.count-circle span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.count-circle:after {
  position: absolute;
  content: "";
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  left: 5px;
  top: 5px;
}

.count-circle:before {
  position: absolute;
  content: "";
  border: 20px solid var(--button-bg-color-01);
  border-radius: 50%;
  height: 0;
  width: 0;
  transform: rotate(45deg);
}

.count-circle.step1:before {
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.count-circle.step2:before {
  border-left-color: transparent;
  border-bottom-color: transparent;
}

.count-circle.step3:before {
  border-left-color: transparent;
}

.count-circle.step4:before {
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
}

/* Css classes for Tags Input component */
.tags-input {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 10px;
}

/* .tags-input:focus-within{
    border: 1px solid #0052cc;
  } */
input[name="tags"] {
  flex: 1;
  border: none;
  height: 43px;
  font-size: 14px;
  text-align: start;
  padding: 0px 0 0 0;
}

input:focus {
  outline: transparent;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fcfcfc;
  padding: 0px 8px;
  font-size: 14px;
  font-weight: 500;
  list-style: none;
  border-radius: 5px;
  margin: 0px 8px 8px 0;
  background: var(--button-bg-color-01);
}

.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 18px;
  margin-left: 8px;
  color: var(--neutral-white-00);
  border-radius: 50%;
  cursor: pointer;
}

/* Loader center */
.loader-center {
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}

/*Pulse Loader center */
.Pulse-loader-center {
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}

/* tags inpu ensds here  */

/* drag and drop file */

ul {
  margin: 0;
}

strong {
  display: inline-block;
  margin-top: 20px;
}

.dropzone {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  width: 100%;
}

/* for dropdown */
.dropdown-menu {
  height: auto;
  max-height: 300px;
  overflow-y: scroll;
}

.dropdown-menu-ar {
  text-align: right !important;
}

/* Multiselect dropdown */

.multiSelectContainer-ar {
  text-align: right !important;
}

/* on hover effect for sort variant page */
#tr-sort:hover {
  background-color: var(--neutral-white-02);
}

/* for model language drop down width */
.model-drop-width {
  border-radius: 0.7rem !important;
  max-width: auto;
  width: 160px;
}

/* input styling for dropdown such as countries state city which has lots of data */
input[id="search-component"] {
  border: none;
  width: 100%;
}

.text-style {
  text-overflow: "...";
}

/* set border radius of prefix dropdown */
.prefix-dropdown-left-corner {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.prefix-dropdown-right-corner-ar {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.prefix-dropdown-right-corner {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.prefix-dropdown-left-corner-ar {
  margin-right: -1px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

/* custom style for react datepicker */
.react-datepicker__header {
  text-align: center;
  background-color: #fff !important;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50px 50px 50px 50px !important;
  background-color: var(--button-bg-color-01) !important;
  color: #fff;
}

.react-datepicker {
  font-family: "Inter", sans-serif !important;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #e4e4e4 !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__month-container {
  float: left;
  padding: 10px;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 15px !important;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #efefef !important;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__day-name {
  color: #9a9fa5 !important;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.datepicker-input-style {
  display: flex !important;
  width: 7.5rem !important;
  max-width: 100% !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 0.7rem !important;
  padding: 0.375rem 0.75rem !important;
  border-width: 2px !important;
}

.datepicker-input-style-without-border {
  display: flex !important;
  width: 7.5rem !important;
  max-width: 100% !important;
  border: 0px solid #fff !important;
  border-radius: 0.7rem !important;
  padding: 0.375rem 0.75rem !important;
  border-width: 2px !important;
}

.datepicker-style-without-border {
  display: flex !important;
  width: 100% !important;
  /* max-width: 100% !important; */
  border: 0px solid #fff !important;
  border-radius: 0.7rem !important;
  padding: 0.375rem 0.75rem !important;
  border-width: 2px !important;
}

/* react select searhable dropdown */

.css-1okebmr-indicatorSeparator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: #fff !important;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}

.css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%) !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 0.7rem !important;
  border-width: 2px !important;
  border-color: #dee2e6 !important;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  outline: none !important;
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}

.css-1pahdxg-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%) !important;
  border-color: 1px solid #dee2e6 !important;
  border-radius: 4px;
  /* border-style: solid; */
  border-width: 1px;
  box-shadow: none !important;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.css-1pahdxg-control:hover {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%) !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 0.7rem !important;
  border-width: 2px !important;
  border-color: #dee2e6 !important;
  box-shadow: none !important;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  outline: none !important;
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}

.searchWrapper {
  background-color: hsl(0, 0%, 100%);
  border: 1px solid #dee2e6 !important;
  border-radius: 0.7rem !important;
  border-width: 2px !important;
  border-color: #dee2e6 !important;
  padding: 5px;
  min-height: 22px;
  position: relative;
}

.highlightOption {
  background: #deebff !important;
  color: #fff;
}

.multiSelectContainer li:hover {
  background: #deebff !important;
  color: #000 !important;
  cursor: pointer;
}

.chip {
  padding: 4px 10px;
  background: var(--button-bg-color-01) !important;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px !important;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500 !important;
  line-height: 19px;
  color: #fcfcfc !important;
}

.css-9gakcf-option {
  background-color: #ffffff !important;
  color: #000 !important;
}

.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #fff;
  background-color: #fff !important;
  border-color: #6c757d;
}

/* sign in image sizing  nordwoodThemes*/
.imgbox {
  display: grid;
  height: 100%;
}

.center-fit {
  width: 100%;
  max-width: 100%;
  max-height: 79vh;
  margin: auto;
}

.otp-input-style {
  width: 2.5rem !important;
  text-align: center;
  height: 3rem !important;
  border-radius: 0.7rem;
  border: 2px solid #dee2e6 !important;
}

/* for tool tip */
.visible {
  height: 3em;
  width: 4em;
}

.proID {
  text-overflow: ellipsis;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
}

.proID:hover {
  text-overflow: clip;
  width: auto;
  white-space: normal;
}

/* for client and supplier checkbox */
.form-check-input {
  width: 1.2em;
  height: 1.2em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1.5px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.28em;
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

/* three dots dropdown */
.threedots:after {
  content: "\2807";
  color: #000;
  font-size: 30px;
}

/* tooltip for copy clipboard */
/* .tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
} */

/* small toast */

#toast {
  visibility: hidden;
  min-width: 250px;
  margin-left: -200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: black;
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 16px;
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.checkicon i {
  font-size: 40px;
  color: #47d764;
}

/* timeline classes  */
.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: var(--brand-color-04) !important;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgb(0 0 0 / 8%),
    0 3px 0 4px rgb(0 0 0 / 5%);
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0rgba (0, 0, 0, 0.08),
    0 3px 0 4pxrgba (0, 0, 0, 0.05);
}

.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  /* background: var(--line-color); */
  background: var(--button-bg-color-01) !important;
}

.vertical-timeline-element {
  position: relative;
  margin: 1em 0 !important;
}

.vertical-timeline-element-content p {
  margin: 0em 0 0 !important;
  line-height: 1.6;
}

/* giftcard and merchandise preview images */
#product-preview {
  width: 350px;
  height: 300px;
  background-size: cover;
}

@media screen and (max-width: 700px) {
  #product-preview {
    width: 100%;
    height: auto;
    background-size: cover;
  }
}

/* for giftcard and merchandise thumbnail image */
#product-thumbnail {
  width: 50px;
  height: 50px;
  background-size: cover;
}

@media screen and (max-width: 700px) {
  #product-thumbnail {
    width: 100%;
    height: auto;
    background-size: cover;
  }
}

/* for infinite scroll component */
.infinite-scroll-component {
  overflow: hidden !important;
}

/* css for email template */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter";
}

.row-cong {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.row-cong .col-cong {
  flex-direction: column;
  width: 50%;
  margin-left: 20px;
}

.row-cong .col-cong-2 {
  flex-direction: column;
  width: 50%;
}

.col-barcode {
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-qr {
  display: flex;
  flex-direction: row;
}

.row-terms-and-features {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.row-terms-and-features .col-terms-and-features {
  flex-direction: column;
  width: 100%;
  margin-right: 5%;
  text-align: justify;
}

.row-terms-and-features .col-terms-and-features-2 {
  flex-direction: column;
  align-items: center;
  width: 30%;
}

.row-logo-contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.responsive {
  max-width: 100%;
  height: auto;
}

.heading {
  font-size: 25px;
  font-weight: 600;
}

.copyRight {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #bebebe !important;
}

.typo {
  font-size: 16px;
  font-weight: normal;
}

.BG {
  background: rgba(42, 133, 255, 0.08);
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
  justify-content: center;
  font-size: 11.88px;
  color: #2a85ff;
  width: 30px;
  height: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  align-items: center;
  margin-left: 20px;
  margin-top: 10px;
}

/* tooltip */
/* Popup container - can be anything you want */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: fit-content;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 95%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.expire-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 769px) {
  .row-cong {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .row-cong .col-cong {
    flex-direction: column;
    width: 100%;
  }

  .row-cong .col-cong-2 {
    flex-direction: column;
    width: 100%;
  }

  .col-barcode {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .row-qr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .row-terms-and-condition {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .row-terms-and-features {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .row-logo-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  #giftcard-image {
    width: 100%;
    height: auto;
  }

  .row-terms-and-features .col-terms-and-features {
    flex-direction: column;
    width: 100%;
    margin-right: 0%;
  }

  .row-terms-and-features .col-terms-and-features-2 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .space {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 12px;
  }

  .expire-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
  }
}

.email-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  position: relative;
}

/* center div */
.offline-container {
  display: flex;
  justify-content: center;
  height: 300px;
  position: relative;
}

.offline-vertical-center {
  text-align: center;
  margin: 0;
  position: absolute;
  top: 100%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Below header component div class */
.outletDiv {
  position: absolute;
  top: 100px;
  bottom: 0px;
  right: 0;
  left: 330px;
  transition: 0.5s;
}

.outletDiv.active {
  position: absolute;
  top: 100px;
  bottom: 0px;
  right: 0;
  left: 70px;
}

.outletDiv-ar {
  position: absolute;
  top: 100px;
  bottom: 0px;
  left: 0;
  right: 330px;
  transition: 0.5s;
}

.outletDiv-ar.active {
  position: absolute;
  top: 100px;
  bottom: 0px;
  right: 0;
  right: 70px;
}

/* for table sorting through table colums */
.descending::after {
  content: "\25b4";
}

.ascending::after {
  content: "\25be";
}

.descending::after,
.ascending::after {
  margin-left: 5px;
}

/* for merchandise model image div container */
.merchandiseModelImgContainer {
  position: relative;
  width: 270px;
  top: 0;
  left: 25px;
}

.slider_prev_btn,
.slider_next_btn {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.slider_prev_btn {
  left: -40px !important;
}

.slider_next_btn {
  right: -40px !important;
}

.prevCard_slider_prev_btn,
.prevCard_slider_next_btn {
  position: absolute;
  /* top: 170px;
  bottom: 0; */
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.prevCard_slider_prev_btn {
  left: 0px;
}

.prevCard_slider_next_btn {
  right: 0px;
}
