.carousel-control-prev{
    /* width: 60px;
    height: 60px; */
    /* background: red; */
}

.carousel-control-prev .carousel-control-prev-icon{
    color: black;
    background-color: rgba(0, 0, 0, 0.486);
    border-radius: 50%;
    padding: 20px;
    font-size: 20px;
}

.carousel-control-next .carousel-control-next-icon{
    color: black;
    background-color: rgba(0, 0, 0, 0.486);
    border-radius: 50%;
    padding: 20px;
    font-size: 20px;
    rotate: 90deg;
}