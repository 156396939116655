.carousel-control-prev .carousel-control-prev-icon {
    color: black;
    background-color: rgba(0, 0, 0, 0.486);
    border-radius: 50%;
    padding: 20px;
    font-size: 20px;
  }
  
  .carousel-control-next {
    right: 20px !important;
  }
  
  .carousel-control-next .carousel-control-next-icon {
    color: black;
    background-color: rgba(0, 0, 0, 0.486);
    border-radius: 50%;
    padding: 20px;
    font-size: 20px;
    rotate: 90deg;
  }
  
  #product-preview {
    width: 270px !important;
    height: 250px !important;
    background-size: cover;
    margin: 15px 0;
  }
  