.topbar-container {
  position: fixed;
  width: calc(100% - 335px);
  left: 330px;
  min-height: 9%;
  background: #ffffff;
  transition: all 0.5s ease;
  z-index: 99;
  transition: 0.5s;
}

.topbar-container.active {
  width: calc(100% - 70px);
  left: 70px;
}

.topbar-container-ar{
  position: fixed;
  width: calc(100% - 335px);
  right: 330px;
  min-height: 9%;
  background: #ffffff;
  transition: all 0.5s ease;
  z-index: 99;
}

.topbar-container-ar.active {
  width: calc(100% - 70px);
  right: 70px;
}
