.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 330px;
  z-index: 99;
  background: #ffffff;
  /* padding: 6px 14px; */
  transition: all 0.5s ease;
  overflow: auto;
}

.sidebar.active {
  width: 70px;
}

.sidebar-ar{
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 330px;
  z-index: 99;
  background: #ffffff;
  /* padding: 6px 14px; */
  transition: all 0.5s ease;
  overflow: auto;
}

.sidebar-ar.active{
  width: 70px;
}


.menuToggle{
  position: relative;
  width: 40px;
  height: 40px;
  /* background: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.sidebar.active .menuToggle{
  width: 40px;
  cursor: pointer;
  
}

.sidebar-ar.active .menuToggle{
  width: 40px;
  cursor: pointer;
}

.menuToggle::before{
  content: '';
  position: absolute;
  width: 32px;
  height: 2px;
  background: #555;
  transform: translateY(-10px);
  box-shadow: 0 10px #555;
  transition: 0.5s;
}

.menuToggle::after{
  content: '';
  position: absolute;
  width: 32px;
  height: 2px;
  background: #555;
  transform: translateY(10px);
  transition: 0.5s;
}

.sidebar .menuToggle::before{
  transform: translateY(0px) rotate(45deg); 
  box-shadow: 0 0 #555;
}

.sidebar-ar .menuToggle::before{
  transform: translateY(0px) rotate(45deg); 
  box-shadow: 0 0 #555;
}

.sidebar .menuToggle::after{
  transform: translateY(0px) rotate(-45deg); 
  box-shadow: 0 0 #555;
}

.sidebar-ar .menuToggle::after{
  transform: translateY(0px) rotate(-45deg); 
  box-shadow: 0 0 #555;
}



.sidebar.active .menuToggle::before{
  transform: translateY(-10px) rotate(0); 
  box-shadow: 0 10px #555;
}

.sidebar-ar.active .menuToggle::before{
  transform: translateY(-10px) rotate(0); 
  box-shadow: 0 10px #555;
}

.sidebar.active .menuToggle::after{
  transform: translateY(10px) rotate(0); 
}

.sidebar-ar.active .menuToggle::after{
  transform: translateY(10px) rotate(0); 
}

.sidebar.active .logo_container .logo {
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
}

.sidebar-ar.active .logo_container .logo {
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
}

.sidebar .menu_icon {
  position: absolute;
  left: 90%;
  cursor: pointer;
}

.sidebar-ar .menu_icon {
  position: absolute;
  right: 90%;
  cursor: pointer;
}

.sidebar.active .menu_icon {
  left: 20%;
  cursor: pointer;
  /* transform: translateX(-50%); */
}

.sidebar-ar.active .menu_icon{
  right: 20%;
  cursor: pointer;
}

.sidebar.active .sidenav_title{
  display: none;
}

.sidebar-ar.active .sidenav_title{
  display: none;
}

.dropdown_icon{
  width: 90%;
  justify-content: space-between;
  align-items: center;
}

.sidebar.active .dropdown_icon {
  opacity: 0;
  pointer-events: auto;
}

.sidebar-ar.active .dropdown_icon{
  opacity: 0;
  pointer-events: auto;
}

.dropdown_icon .sidebar::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.sidebar.active .without_dropdown_icon{
  opacity: 0;
}

.sidebar-ar.active .without_dropdown_icon{
  opacity: 0;
}

.sidebar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

.sidebar-ar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

.sidebar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.sidebar-ar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
